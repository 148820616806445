import { __awaiter } from "tslib";
import ShowInfo from "@/modal/ShowInfo.vue";
import * as crypto from "crypto";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import formatNumber from "@/helper/number";
import { MigrateSolanaUseCase } from "@/usecases/GetSolananUseCase";
import { ethers } from "ethers";
export default {
    components: {
        ShowInfo,
    },
    data() {
        return {
            formatNumber,
            contract: null,
            showError: false,
            open_confirm: false,
            open_done: false,
            open_error: false,
            airdrop_info: new MigrateSolanaUseCase(),
            detail_airdrop: {},
            showLoading: true,
            isSubmitTransaction: false,
            erc20TokenABI: [
                {
                    "constant": true,
                    "inputs": [{ "name": "_owner", "type": "address" }],
                    "name": "balanceOf",
                    "outputs": [{ "name": "balance", "type": "uint256" }],
                    "type": "function"
                },
                {
                    "constant": true,
                    "inputs": [],
                    "name": "decimals",
                    "outputs": [{ "name": "", "type": "uint8" }],
                    "type": "function"
                }
            ],
        };
    },
    mounted() {
        if (this.getProvider) {
            this.createContractObj().then(() => {
                this.getSolanaInfo();
            });
        }
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['IsLogin', 'getAddress', 'getProvider'])), { isContinue() {
            return this.detail_airdrop.claimed === false && !this.isSubmitTransaction && this.checkWalletSolana();
        } }),
    // watch: {
    //   'detail_airdrop': function () {
    //     console.log(this.detail_airdrop);
    //     if (this.detail_airdrop && this.detail_airdrop.length !== 0) {
    //       this.showLoading = false;
    //     }
    //   },
    // },
    methods: {
        md5Encode(input) {
            return crypto.createHash("md5").update(input).digest("hex");
        },
        createContractObj() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                this.contract = new ethers.Contract(process.env.VUE_APP_MAIN_TOKEN_CONTRACT || "", this.erc20TokenABI, signer);
            });
        },
        checkWalletSolana() {
            let isValid = this.detail_airdrop.solanaWallet !== undefined && this.detail_airdrop.solanaWallet !== "";
            if (isValid) {
                isValid = this.detail_airdrop.solanaWallet.length == 44;
            }
            this.showError = !isValid;
            return isValid;
        },
        getSolanaInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                let self = this;
                yield this.airdrop_info.getData(this.getAddress).then(function () {
                    self.detail_airdrop = self.airdrop_info.getDataFromAPI();
                });
                yield this.contract.balanceOf(this.getAddress).then(function (result) {
                    const amountToken = ethers.utils.formatUnits(result, process.env.VUE_APP_MAIN_TOKEN_DECIMAL);
                    self.detail_airdrop.amount = amountToken.toString();
                }).catch(function (error) {
                    console.error(error);
                });
                this.showLoading = false;
            });
        },
        handleBack() {
            this.$router.push({ name: "Events" });
        },
        openModalConfirm() {
            this.open_confirm = true;
        },
        handleConfirm(e) {
            e.preventDefault();
            this.hideModal(0);
            this.isSubmitTransaction = true;
            this.handleClaimContract();
        },
        handleClaimContract() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                let message = this.detail_airdrop.solanaWallet + "|" + this.detail_airdrop.amount;
                const messageHash = this.md5Encode(message + "|" + this.getAddress);
                message = message + "|" + messageHash;
                const signature = yield signer.signMessage(message);
                this.showLoading = true;
                yield this.airdrop_info.submitClaim({
                    signature: signature,
                    publicKey: this.getAddress,
                    message: message,
                }).then((result) => __awaiter(this, void 0, void 0, function* () {
                    if (result) {
                        this.detail_airdrop.claimed = true;
                        this.open_done = true;
                    }
                    else {
                        this.open_error = true;
                    }
                })).catch((error) => {
                    console.error(error);
                    this.open_error = true;
                });
            });
        },
        hideModal(type) {
            switch (type) {
                case 0:
                    this.open_confirm = false;
                    break;
                case 1:
                    this.showLoading = false;
                    this.open_done = false;
                    this.getSolanaInfo();
                    this.isSubmitTransaction = false;
                    break;
                case 2:
                    this.showLoading = false;
                    this.open_error = false;
                    this.getSolanaInfo();
                    this.isSubmitTransaction = false;
                    break;
            }
        },
        contact() {
            window.open("https://roboglobal.info/linktree", "_blank");
            this.hideModal(2);
        }
    },
};
