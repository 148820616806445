import { __awaiter } from "tslib";
import { checkWalletClaim, walletClaim, } from "@/api/solana/solana";
export class MigrateSolanaUseCase {
    constructor() {
        this.data = {};
    }
    getData(address) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield checkWalletClaim(address);
            if (result == undefined) {
                return;
            }
            this.data.is_eligible = result.status;
            this.data.claimed = !result.status;
            this.data.address = result.address;
        });
    }
    getDataFromAPI() {
        return this.data;
    }
    submitClaim(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield walletClaim(data);
                if (!result || result.status === false) {
                    return false;
                }
                return true;
            }
            catch (error) {
                console.error("Error submitting claim:", error);
                return false;
            }
        });
    }
}
