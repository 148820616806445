import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
// const BASE_URL = "http://localhost:8080/api/v1/solana"
const BASE_URL = "https://claim-api.roboglobal.info/api/v1/solana";
export function checkWalletClaim(address) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/check-public-key", {
            params: {
                wallet: address,
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(ResponseData);
    });
}
export function walletClaim(data) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.post(BASE_URL + "/claim-public-key", data, {
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(ResponseData);
    });
}
